//==============================================================================
//==============================================================================
import * as React from 'react';

import { convertCustomerAttributes } from '../../utilities/data-attribute-parser';

import { ISalesRepData } from './sales-rep.data';
import { ISalesRepProps } from './sales-rep.props.autogenerated';

//==============================================================================
// GLOBALS
//==============================================================================
const SALES_REP_ATTRIBUTE = 'CusSalesRep';

//==============================================================================
// INTERFACES
//==============================================================================
export interface ISalesRepViewProps extends ISalesRepProps<ISalesRepData> {
    reps: SalesRepEntry[];
    label: string;
}

export type SalesRepEntry = {
    name: string;
    email: string;
    phone: string;
};

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 *
 * SalesRep component
 * @extends {React.PureComponent<ISalesRepProps<ISalesRepData>>}
 */
class SalesRep extends React.PureComponent<ISalesRepProps<ISalesRepData>> {

    //------------------------------------------------------
    //------------------------------------------------------
    public render(): JSX.Element | null {

        // Grab the list of reps
        const repData = this.getRepData();

        // If there are no reps, don't render anything
        // If the customer list hasn't been loaded yet, this will cause SSR issues!
        // It should be safe as long as the data action is executed on the server
        if (repData.length < 1) {
            return null;
        }

        // Render something
        const viewProps: ISalesRepViewProps = {
            ...this.props,

            label: this.props.config.labelText,
            reps: repData
        };

        return this.props.renderView(viewProps);
    }

    //------------------------------------------------------
    // Returns a formatted list of sales reps
    //------------------------------------------------------
    private getRepData(): SalesRepEntry[] {

        // Ensure customer data has been loaded (if not, we'll have SSR issues!)
        const customerData = this.props.data.customerInformation.result;
        if (!customerData) {
            this.props.telemetry.warning('SALES REP: Customer data not loaded in time for render');
            return [];
        }

        // Convert customer attributes to a useful format
        const attributes = convertCustomerAttributes(customerData.Attributes);

        // Grab the sales reps string, if present. Abort if not.
        const rawSalesReps = attributes[SALES_REP_ATTRIBUTE];
        if (!rawSalesReps) {
            return [];
        }

        // Convert the raw string to a useful format
        return this.convertRepData(rawSalesReps as string);
    }

    //------------------------------------------------------
    // Convert the raw attribute string to a list of reps
    //------------------------------------------------------
    private convertRepData(salesRepsString: string): SalesRepEntry[] {
        const split = salesRepsString.split(';');

        // Create a record out of every 3 entries
        const output: SalesRepEntry[] = [];
        while (split.length >= 3) {
            output.push({
                name: split.shift()!.trim(),
                phone: split.shift()!.trim(),
                email: split.shift()!.trim(),
            });
        }

        return output;
    }
}

export default SalesRep;
