//==============================================================================
// PDP Attributes Localized
//
// Renders a pre-configured list of attributes of the current page's product.
// Clone of PDP Attributes module that combines friendly name and internal names
// of attributes to support multi-language sites.
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { RichTextComponent } from '@msdyn365-commerce/core';
import { Button } from '@msdyn365-commerce-modules/utilities';
import { AttributeValue, SimpleProduct, IDictionary } from '@msdyn365-commerce/retail-proxy';

import { convertProductAttributesLocalized, AttributesLocalized, CommerceValueTypes } from '../../utilities/data-attribute-parser';
import { IPdpAttributesLocalizedData } from './pdp-attributes-localized.data';
import { IAttributesData, IPdpAttributesLocalizedProps, IPdpAttributesLocalizedResources } from './pdp-attributes-localized.props.autogenerated';

//==============================================================================
// INTERFACES AND CONSTANTS
//==============================================================================

// Return type for _parseAttribute()
type ParsedAttribute<T = CommerceValueTypes | IDictionary<string>> = {
    name: string,
    value: T,
    type: string
};

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'pdp-attributes';
const cls = (fragment: string) => `${BASE_CLASS}__${fragment}`;

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * PdpAttributesLocalized component
 * @extends {React.Component<IPdpAttributesLocalizedProps<IPdpAttributesLocalizedData>>}
 */
//==============================================================================
class PdpAttributesLocalized extends React.Component<IPdpAttributesLocalizedProps<IPdpAttributesLocalizedData>> {
    private combinedAttributes: AttributesLocalized | undefined;

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const productSimpleData = this.props.data.product.result;
        const productSpecificationData = this.props.data.productSpecificationData.result;

        return (
            <div className={classnames(BASE_CLASS, this.props.config.className)}>
                {productSpecificationData && productSimpleData && this._getAttributesList(productSimpleData, productSpecificationData)}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    // Gets converted attributes, combines pseudo simple
    // attributes, and renders attributes list
    //------------------------------------------------------
    private _getAttributesList(productSimpleData: SimpleProduct, productSpecificationData: AttributeValue[]): JSX.Element {
        if (!this.combinedAttributes) {
            const convertedAttributes = convertProductAttributesLocalized(productSpecificationData);

            if (convertedAttributes) {
                this.combinedAttributes = this._appendPseudoSimpleAttributes(productSimpleData, convertedAttributes);
            }
        }

        return this._renderAttributesList();
    }

    //------------------------------------------------------
    // Appends reformatted pseudo simple attributes to
    // combined attributes object
    //------------------------------------------------------
    private _appendPseudoSimpleAttributes(simpleProduct: SimpleProduct, convertedAttributes: AttributesLocalized): AttributesLocalized {
        this.props.config.attributes.map((attr) => {
            if (simpleProduct[attr.attributeName]) {
                convertedAttributes[attr.attributeName] = simpleProduct[attr.attributeName];
                convertedAttributes.meta[attr.attributeName] = 'string';
            }
        });

        return convertedAttributes;
    }

    //------------------------------------------------------
    // Returns JSX of the attribute list's render
    //------------------------------------------------------
    private _renderAttributesList(): JSX.Element {
        return (
            <React.Fragment>
                {this.props.config.attributes.map((attr, index) => {
                    return (
                        <React.Fragment key={index}>
                            {this._renderAttribute(attr)}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    //------------------------------------------------------
    // Returns JSX of a specific attribute's render
    //------------------------------------------------------
    private _renderAttribute(cmsAttribute: IAttributesData): JSX.Element | null {
        const attribute = this._parseAttribute(cmsAttribute.attributeName);

        // Do not render if attribute doesn't exist or has no value
        if (!attribute || (!attribute.value && attribute.value !== 0 && attribute.value !== false)) {
            return null;
        }

        // Do not render if hideFalse is checked and attribute value is false
        if (cmsAttribute.hideFalse && attribute.value === false) {
            return null;
        }

        // Pipeline for rendering based on attribute type
        const attributeType = {
            string:     this._richtextRender,
            number:     this._plaintextRender,
            Date:       this._dateRender,
            boolean:    this._booleanRender,
            url:        this._urlRender
        };

        // Differentiates url type from string type
        let type = attribute.type;
        if (attribute.value?.toString().startsWith('https://' || 'http://')) {
            type = 'url';
        }

        // Function call to render attribute via pipeline
        const renderedAttribute = attributeType[type](attribute, cmsAttribute, this.props.resources);
        // this.props.resources is not accessible in the individual render
        // functions for some reason, so had to be passed through as a parameter

        return this._renderCell(renderedAttribute, cmsAttribute, attribute.name);
    }

    //------------------------------------------------------
    // Renders rich text attribute
    //------------------------------------------------------
    private _richtextRender(text: ParsedAttribute<string>, cmsAttribute: IAttributesData): JSX.Element | null {
        return <RichTextComponent className={cls('attribute-value-richtext')} text={`${text.value}${cmsAttribute.suffixText || ''}`}/>;
    }

    //------------------------------------------------------
    // Renders plain text attribute
    //------------------------------------------------------
    private _plaintextRender(text: ParsedAttribute<number>, cmsAttribute: IAttributesData): JSX.Element | null {
        return <span className={cls('attribute-value-text')}>{text.value}{cmsAttribute.suffixText || ''}</span>;
    }

    //------------------------------------------------------
    // Renders date attribute
    //------------------------------------------------------
    private _dateRender(date: ParsedAttribute<string>, cmsAttribute: IAttributesData): JSX.Element {
        return <span className={cls('attribute-value-date')}>{new Date(date.value).toUTCString()}{cmsAttribute.suffixText}</span>;
    }

    //------------------------------------------------------
    // Renders boolean attribute
    //------------------------------------------------------
    private _booleanRender(bool: ParsedAttribute<boolean>, cmsAttribute: IAttributesData, resources: IPdpAttributesLocalizedResources): JSX.Element {
        const booleanTrue = resources.pdpAttributes_booleanTrue;
        const booleanFalse = resources.pdpAttributes_booleanFalse;
        return (
            <span className={
                classnames(
                    cls('attribute-value-boolean'),
                    cls(`attribute-value-boolean-${bool.value}`)
                )
            }>
                {bool.value ? booleanTrue : booleanFalse}
            </span>
        );
    }

    //------------------------------------------------------
    // Renders URL attribute in the form of a CTA button
    //------------------------------------------------------
    private _urlRender(url: ParsedAttribute<string>, cmsAttribute: IAttributesData): JSX.Element {
        return (
            <Button
                className={cls('attribute-value-cta')}
                title={cmsAttribute.labelOverride || cmsAttribute.attributeName || url.value}
                href={url.value}
                target='_blank'
            />
        );
    }

    //------------------------------------------------------
    // Renders div containing each attribute
    //------------------------------------------------------
    private _renderCell(cellBody: JSX.Element, cmsAttribute: IAttributesData, friendlyName: string): JSX.Element {
        const attributeName = cmsAttribute.labelOverride || friendlyName;

        return (
            <div className={classnames(cls('attribute'), cmsAttribute.className)}>
                {!cmsAttribute.noLabel && <div className={cls('attribute-label')}>{attributeName}</div>}
                <div className={cls('attribute-value')}>
                    {cellBody}
                </div>
            </div>
        );
    }

    //------------------------------------------------------
    // Parses and returns the attribute value queried
    //------------------------------------------------------
    private _parseAttribute(name: string): ParsedAttribute {
        return {
            name: this.combinedAttributes![name]?.friendlyName || '',
            value: this.combinedAttributes![name]?.value || '',
            type: this.combinedAttributes!.meta[name]
        };
    }
}

export default PdpAttributesLocalized;
