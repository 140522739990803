//==============================================================================
//==============================================================================
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import { ISalesRepViewProps } from './sales-rep';

//==============================================================================
// GLOBALS
//==============================================================================
const BASE_CLASS = 'sales-rep';
const cls = (fragment: string) => `${BASE_CLASS}__${fragment}`;

//==============================================================================
// VIEW CLASS COMPONENT
//==============================================================================

//==========================================================
// If there aren't any sales reps, this won't be rendered
//==========================================================
@observer
class SalesRepView extends React.Component<ISalesRepViewProps> {

    @observable private isOpen: Boolean = false;

    //------------------------------------------------------
    //------------------------------------------------------
    constructor(props: ISalesRepViewProps) {
        super(props);

        this.toggleClass = this.toggleClass.bind(this);
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public render(): JSX.Element | null {
        return (
            <div className={BASE_CLASS}>

                {/* Label */}
                <div className={cls('label')} onClick={this.toggleClass}>{this.props.label}</div>

                {/* Container, which shows or hides the content */}
                <div className={classnames(cls('container'), { hidden: !this.isOpen })}>

                    {/* List of reps */}
                    {this.props.reps.map((rep, index) => (
                        <div key={index} className={cls('entry')}>
                            <div className={cls('entry-name')}>{rep.name}</div>
                            <div className={cls('entry-email')}>{rep.email}</div>
                            <div className={cls('entry-phone')}>{rep.phone}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private toggleClass() {
        this.isOpen = !this.isOpen;
    }
}
export default SalesRepView;